import React from "react";

const StayAnywhere: React.FC = () => {
  return (
    <>
      <div className="stay-anywhere-div mt-16 pb-8">
        <div className="div-heading mb-5">
          <h5 className="heading">Stay anywhere worldwide</h5>
          <p className="sub-heading">
            Search Flights to our most popular destinations.
          </p>
        </div>
        <div className="stay-anywhere-img grid grid-cols-1 gap-2 md:grid-cols-3">
          <div className="img-div relative h-64">
            <img
              className="object-cover w-full h-full rounded"
              src="/stay_home_img/area-img/hotels.png"
              alt=""
            />
            <div className="place-name-div absolute inset-x-0 bottom-0 p-4">
              <h3 className="place-name">Hotel</h3>
            </div>
          </div>
          <div className="img-div relative h-64">
            <img
              className="object-cover w-full h-full rounded"
              src="/stay_home_img/area-img/home-holiday.png"
              alt=""
            />
            <div className="place-name-div absolute inset-x-0 bottom-0 p-4">
              <h3 className="place-name">Home Holidays</h3>
            </div>
          </div>
          <div className="img-div relative h-64">
            <img
              className="object-cover w-full h-full rounded"
              src="/stay_home_img/area-img/unique-stay.png"
              alt=""
            />
            <div className="place-name-div absolute inset-x-0 bottom-0 p-4">
              <h3 className="place-name">Unique Stays</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StayAnywhere;
