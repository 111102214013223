import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

import searchSlice from './slices/search-slice';
import searchVarSlice from './slices/search-var-slice';
import currencySlices from './slices/currency-slices';
import testSlice from './slices/test-slice';
import hashInfoSlice from './slices/hash-info-slice';
import userSlice from './slices/user-slice';
import orderStatusSlice from './slices/order-status-slice';
import generateCryptoAddressSlice from './slices/generate-crypto-address-slice';
import hotelInfoSlice from './slices/hotel-info-slice';
import roomInfoSlice from './slices/room-info-slice';
import couponSlice from './slices/coupon-slice';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

const rootReducer = combineReducers({
  search: searchSlice,
  searchVar: searchVarSlice,
  crypto: generateCryptoAddressSlice,
  currency: currencySlices,
  hashInfo: hashInfoSlice,
  hotelInfo: hotelInfoSlice,
  test: testSlice,
  user: userSlice,
  orderStatus: orderStatusSlice,
  room: roomInfoSlice,
  coupon: couponSlice,

});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'hotelInfo',
    'roomInfo',
    'searchVar',
    'crypto',
    'prebook',
    'hashInfo',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
