import React from "react";

const PaymentPriceSummary = ({ order }: { order: any }) => {
  return (
    <>
      <div className="">
        <p className="text-[#27273F] font-semibold text-[24px] text-center">Price breakdown</p>

        <table>
          <tbody className="text-[#27273F] text-[18px] ">
            <tr className="">
              <td className="p-1">{order?.room_type}</td>
              <td className="p-1 text-end"> {order?.no_of_rooms} X {parseFloat(order?.fiatPrice || 0).toFixed(2)} {order?.fiatType}</td>
            </tr>
            <tr className="">
              <td className="p-1">Subtotal</td>
              <td className="p-1 text-end"> {parseFloat(order?.fiatPrice || 0).toFixed(2)} {order?.fiatType}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr className="text-[#36374A] text-[18px] font-bold border-t-2 border-b-2">
              <td className="p-1">Amount to pay</td>
              <td className="p-1 text-end">{order?.crypto_price} {order?.crypto_type}/{order?.crypto_network}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};
export default PaymentPriceSummary;
