import React from 'react';
import { hotelFacilities } from '../../Shared/all-images';

interface AmenityGroups {
  amenities: string[];
  group_name: string;
}

interface HotelInfoData {
  amenity_groups: AmenityGroups[];
}

interface HotelFacilitiesProps {
  data: HotelInfoData;
}

const HotelFacilities: React.FC<HotelFacilitiesProps> = ({ data }) => {
  const allAmenities = data?.amenity_groups?.flatMap(group =>
    group.amenities.map(amenity => ({ group_name: group.group_name, amenity }))
  ) || [];
  const itemsPerColumn = Math.ceil(allAmenities.length / 4);

  const columns = [0, 1, 2, 3].map(colIndex =>
    allAmenities.slice(colIndex * itemsPerColumn, (colIndex + 1) * itemsPerColumn)
  );
  const displayedGroups = new Set<string>();

  return (
    <div id="facilities">
      <div className='mt-20'>
        <p className='text-[#001A4D] font-bold text-[24px]'>Facilities</p>
        <div className='flex mt-5 flex-col md:flex-row'>
          {columns.map((column, colIndex) => (
            <div key={colIndex} className='flex-1'>
              {column.map((item, index) => {
                const isNewGroup = !displayedGroups.has(item.group_name);

                if (isNewGroup) {
                  displayedGroups.add(item.group_name);
                }

                return (
                  <div key={index} className='mb-2'>
                    {isNewGroup && (
                      <div className='flex items-start gap-2 mb-2'>
                        <img
                          className='w-[20px] h-[20px]'
                          src={(hotelFacilities as any)[item.group_name]?.icon || '/stay_hotel_img/facilities/check.svg'}
                          alt=""
                        />
                        <p className='font-bold text-[#001A4D] md:text-[#000000] leading-5'>
                          {(hotelFacilities as any)[item.group_name]?.label || 'Amenity Group'}
                        </p>
                      </div>
                    )}
                    <p className='ml-8 text-[#001A4D] md:text-[#000000] leading-6'>{item.amenity}</p>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HotelFacilities;