import React from 'react';
import { NavLink } from 'react-router-dom';

const Stepper: React.FC = () => {
    return (
        <div className='flex w-full items-center justify-around'>
            <div className="flex items-center w-full justify-around">
                <div>
                    <NavLink
                        to="/hotelsByRegion"
                        className={({ isActive }) =>
                            `pointer-events-none w-[40px] h-[40px] flex items-center justify-center rounded-full md:w-[60px] md:h-[60px] ${isActive ? 'bg-[#002E8A]' : 'bg-[#D9E0EE]'}`
                        }
                    >
                        {({ isActive }) => (
                            <img
                                className="w-[21px] h-[21px] md:w-[32px] md:h-[32px]"
                                src={isActive ? "/steper_img/select-facility-w.svg" : "/steper_img/select-facility-b.svg"}
                                alt=""
                            />
                        )}
                    </NavLink>
                </div>
                <div className='hidden md:block min-w-[120px]'>
                    <div className='flex justify-start ml-2'>
                        <p>Select facility</p>
                    </div>
                </div>
                <div className="w-full flex justify-center mx-1">
                    <div className="h-[1px] w-full bg-slate-900"></div>
                </div>
            </div>

            <div className="flex items-center justify-around w-full">
                <div>
                    <NavLink
                        to="/hotels"
                        className={({ isActive }) =>
                            `pointer-events-none w-[40px] h-[40px] flex items-center justify-center rounded-full md:w-[60px] md:h-[60px] ${isActive ? 'bg-[#002E8A]' : 'bg-[#D9E0EE]'}`
                        }
                    >
                        {({ isActive }) => (
                            <img
                                className="w-[21px] h-[21px] md:w-[32px] md:h-[32px]"
                                src={isActive ? "/steper_img/select-room-w.svg" : "/steper_img/select-room-b.svg"}
                                alt=""
                            />
                        )}
                    </NavLink>
                </div>
                <div className='hidden md:block min-w-[110px]'>
                    <div className='flex justify-start ml-2'>
                        <p>Select room</p>
                    </div>
                </div>
                <div className="w-full flex justify-center mx-1">
                    <div className="h-[1px] w-full bg-slate-900"></div>
                </div>
            </div>

            <div className="flex items-center w-full justify-around">
                <div>
                    <NavLink
                        to="/hotelDetails/prebook"
                        className={({ isActive }) =>
                            `pointer-events-none w-[40px] h-[40px] flex items-center justify-center rounded-full md:w-[60px] md:h-[60px] ${isActive ? 'bg-[#002E8A]' : 'bg-[#D9E0EE]'}`
                        }
                    >
                        {({ isActive }) => (
                            <img
                                className="w-[21px] h-[21px] md:w-[32px] md:h-[32px]"
                                src={isActive ? "/steper_img/travellers-w.svg" : "/steper_img/travellers-b.svg"}
                                alt=""
                            />
                        )}
                    </NavLink>
                </div>
                <div className='hidden min-w-[140px] md:block'>
                    <div className='flex justify-start ml-2'>
                        <p>Travellers details</p>
                    </div>
                </div>
                <div className="w-full overflow-hidden flex justify-center mx-1">
                    <div className="h-[1px] w-full bg-slate-900"></div>
                </div>
            </div>

            <div className="flex items-center">
                <div>
                    <NavLink
                        to="/payment"
                        className={({ isActive }) =>
                            `pointer-events-none w-[40px] h-[40px] flex items-center justify-center rounded-full md:w-[60px] md:h-[60px] ${isActive ? 'bg-[#002E8A]' : 'bg-[#D9E0EE]'}`
                        }
                    >
                        {({ isActive }) => (
                            <img
                                className="w-[21px] h-[21px] md:w-[32px] md:h-[32px]"
                                src={isActive ? "/steper_img/payment-w.svg" : "/steper_img/payment-b.svg"}
                                alt=""
                            />
                        )}
                    </NavLink>
                </div>
                <div className='hidden w-full md:block'>
                    <div className='w-full flex ml-2'>
                        <p>Payment</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stepper;
