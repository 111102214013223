import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../utils/axiosConfig";
// If you want to manually remove these cookies, you can keep js-cookie
import Cookies from "js-cookie";

interface LoginUserProps {
  closeModal: () => void;
}

const LoginUser: React.FC<LoginUserProps> = ({ closeModal }) => {
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [buttonLoading, setButtonLoading] = useState(false);

  const navigate = useNavigate();

  // Validate user input
  const validateFields = () => {
    const newErrors: Record<string, string> = {};

    if (!emailId) {
      newErrors.emailId = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailId.trim())) {
      newErrors.emailId = "Please enter a valid email address.";
    }
    if (!password) {
      newErrors.password = "Password is required.";
    } else if (password.trim().length > 20) {
      newErrors.password = "Maximum 20 characters allowed.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Optional: flush cookies after 1 hour (logout user automatically)
  const flushCookies = () => {
    Cookies.remove("accessToken");
    Cookies.remove("idToken");
    toast.info("Session expired. Please log in again.");

    setTimeout(() => {
      // Clear localStorage flag
      localStorage.removeItem("isLoggedIn");
      // Reload the page or navigate
      navigate("/");
      window.location.reload();
    }, 2000);
  };

  const handleLogin = async () => {
    setButtonLoading(true);

    // 1) Validate front-end fields
    if (!validateFields()) {
      setButtonLoading(false);
      toast.error("Validation Failed");
      return;
    }

    try {
      // 2) Make login call to your API
      const response = await axiosInstance.post(
        "/b2b/login",
        { email: emailId, password },
        { withCredentials: true } // to include cookies
      );
      const data = response.data;

      // 3) Check response
      if (response.status === 200 && data.success) {
        toast.success("Login Successful");

        // a) Mark user as logged in in localStorage
        localStorage.setItem("isLoggedIn", "true");

        // b) Close modal and reset forms
        closeModal();
        setEmailId("");
        setPassword("");

        // c) Optionally refresh or navigate
        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 1500);

        // d) Optional auto-logout after 1 hour
        setTimeout(flushCookies, 60 * 60 * 1000);
      } else {
        toast.error("Login Failed");
      }
    } catch (error) {
      toast.error("Login Failed");
      console.error("Error during login:", error);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-5 w-full">
      <div className="flex flex-col px-4 gap-5 w-full">
        <div className="flex flex-col gap-3">
          <p>Email</p>
          <TextField
            id="emailId"
            label="Email"
            type="email"
            fullWidth
            size="small"
            value={emailId}
            onChange={(e) => setEmailId(e.target.value)}
            error={!!errors.emailId}
            helperText={errors.emailId}
          />
        </div>

        <div className="flex flex-col gap-3">
          <p>Password</p>
          <TextField
            id="password"
            label="Password"
            type="password"
            fullWidth
            size="small"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
          />
        </div>
      </div>

      <div className="w-full bg-gray-200 px-3 py-2 md:px-4 md:py-3 flex justify-center">
        <button
          onClick={handleLogin}
          disabled={!emailId || !password}
          className={`w-full bg-[#002E8A] text-[#FFFFFF] rounded-lg flex items-center justify-center border px-5 py-3 ${
            !emailId || !password
              ? "opacity-50 cursor-not-allowed"
              : "opacity-100"
          }`}
        >
          {buttonLoading ? (
            <div className="flex items-center gap-2">
              <CircularProgress size={18} color="inherit" />
            </div>
          ) : (
            <span>Login</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default LoginUser;
