import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { parsePhoneNumberFromString } from "libphonenumber-js";

interface Guest {
  first_name: string;
  last_name: string;
  age?: number;
  is_child?: boolean;
}

interface RoomGuests {
  guests: Guest[];
}

interface UserDetails {
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
  additionalRequests: string;
  guestDetails: RoomGuests[];
}

interface UserState {
  userDetails: UserDetails;
  inputErrors: Partial<Omit<UserDetails, "guestDetails">> & {
    guestDetails?: any[][] | undefined;
  };
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  userDetails: {
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    email: "",
    additionalRequests: "",
    guestDetails: [],
  },
  inputErrors: {},
  loading: false,
  error: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<Partial<UserDetails>>) => {
      state.userDetails = { ...state.userDetails, ...action.payload };
    },
    addGuestDetails: (
      state,
      action: PayloadAction<{ roomIndex: number; guest: Guest }>
    ) => {
      const { roomIndex, guest } = action.payload;
      if (!state.userDetails.guestDetails[roomIndex]) {
        state.userDetails.guestDetails[roomIndex] = { guests: [] };
      }
      state.userDetails.guestDetails[roomIndex].guests.push(guest);
    },
    clearGuestDetails: (state) => {
      state.userDetails.guestDetails = [];
    },
    setErrors: (
      state,
      action: PayloadAction<
        Partial<Omit<UserDetails, "guestDetails">> & {
          guestDetails?: any[][] | undefined;
        }
      >
    ) => {
      state.inputErrors = action.payload;
    },

    clearErrors: (state) => {
      state.inputErrors = {};
    },

    validateForm: (state) => {
      const errors: Partial<Omit<UserDetails, "guestDetails">> & {
        guestDetails?: any[][] | undefined;
      } = {};

      // Main User Validation
      // Title validation
      if (!state.userDetails.title) {
        errors.title = "Required";
      }

      // First Name validation
      if (!state.userDetails.firstName) {
        errors.firstName = "Required";
      } else if (state.userDetails.firstName.length > 20) {
        errors.firstName = "First name can't exceed 20 characters";
      }

      // Middle Name (if applicable)
      if (
        state.userDetails.middleName &&
        state.userDetails.middleName.length > 20
      ) {
        errors.middleName = "Middle name cannot exceed 20 characters";
      }

      // Last Name validation
      if (!state.userDetails.lastName) {
        errors.lastName = "Required";
      } else if (state.userDetails.lastName.length > 20) {
        errors.lastName = "Last name can't exceed 20 characters";
      }

      // Phone validation
      if (!state.userDetails.phone) {
        errors.phone = "Required";
      } else {
        const phoneNumber = parsePhoneNumberFromString(state.userDetails.phone);
        if (!phoneNumber || !phoneNumber.isValid()) {
          errors.phone = "Invalid phone number or country code";
        } else if (!phoneNumber.country) {
          errors.phone = "Country code is required";
        }
      }

      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!state.userDetails.email) {
        errors.email = "Required";
      } else if (!emailRegex.test(state.userDetails.email)) {
        errors.email = "Invalid email format";
      } else if (state.userDetails.email.length > 35) {
        errors.email = "Email can't exceed 35 characters";
      }

      // Additional requests validation (if applicable)
      if (
        state.userDetails.additionalRequests &&
        state.userDetails.additionalRequests.length > 100
      ) {
        errors.additionalRequests =
          "Additional requests cannot exceed 100 characters";
      }

      // Other User Validation
      if (state.userDetails.guestDetails.length > 0) {
        errors.guestDetails = state.userDetails.guestDetails.map((room) =>
          room.guests.map((guest) => {
            const guestErrors: {
              first_name?: string;
              last_name?: string;
            } = {};

            // Validate First Name
            if (!guest.first_name) {
              guestErrors.first_name = "Required";
            } else if (guest.first_name.length > 20) {
              guestErrors.first_name = "can't exceed 20 characters";
            }

            // Validate Last Name
            if (!guest.last_name) {
              guestErrors.last_name = "Required";
            } else if (guest.last_name.length > 20) {
              guestErrors.last_name = "can't exceed 20 characters";
            }
            return Object.keys(guestErrors).length ? guestErrors : {};
          })
        );

        const allEmpty = errors.guestDetails.every((roomErrors) =>
          roomErrors.every((error) => Object.keys(error).length === 0)
        );

        if (allEmpty) {
          delete errors.guestDetails;
        }
      }
      state.inputErrors = errors;
    },
  },
});

export const {
  setUserDetails,
  addGuestDetails,
  clearGuestDetails,
  setErrors,
  clearErrors,
  validateForm,
} = userSlice.actions;
export default userSlice.reducer;
