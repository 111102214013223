import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../utils/axiosConfig';
import Stepper from '../Components/Shared/stepper';
import HotelInfoSkeleton from '../Components/Skeleton/hotel-info-skeleton';
import HotelDetails from '../Components/Views/Hotel/hotel-room-section';
import {
  setHotelInfo,
  setFetchStatus,
  setError,
  clearHotelInfo,
} from '../Redux/slices/hotel-info-slice';
import { RootState } from '../Redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Hotel = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // Access hotelInfo data and other state from Redux store
  const hotelInfoData = useSelector((state: RootState) => state.hotelInfo.data);
  const fetchStatus = useSelector((state: RootState) => state.hotelInfo.status);
  const error = useSelector((state: RootState) => state.hotelInfo.error);

  // Local state for loading and other data not stored in Redux
  const [loading, setLoading] = useState(false);
  const [groupedRates, setGroupedRates] = useState([]);
  const [roomCount, setRoomCount] = useState(0);

  const params = new URLSearchParams(location.search);
  const hid = params.get('hid');
  const checkin = params.get('checkin');
  const checkout = params.get('checkout');
  const guestsParam = params.get('guests');
  const residencyParam = params.get('residency');

  useEffect(() => {
    setLoading(true);
    const fetchSingleHotelDetails = async () => {
      dispatch(setFetchStatus('loading'));
      dispatch(clearHotelInfo());

      try {
        const response = await axiosInstance.get(
          '/hotelDetails/singleRoomDetails',
          {
            params: {
              hid: Number(hid),
              checkin: checkin,
              checkout: checkout,
              guests: guestsParam,
              residency: residencyParam
            },
          },
        );

        if (response && response.data.status === 'success') {
          const data = response.data.data;
          dispatch(setHotelInfo(data.hotelInfo)); // Update Redux state with hotelInfo
          setGroupedRates(data.groupedRates); // Set groupedRates in local state
          setRoomCount(data.roomCount); // Set roomCount in local state
          dispatch(setFetchStatus('succeeded'));
          setLoading(false);
        } else {
          dispatch(setFetchStatus('failed'));
          setLoading(false);
        }
      } catch (error) {
        dispatch(setError('Error fetching hotel details'));
        dispatch(setFetchStatus('failed'));
        setLoading(false);
        console.error('Error fetching hotel details:', error);
      }
    };

    fetchSingleHotelDetails();
  }, [dispatch, hid, checkin, checkout, guestsParam, residencyParam]);

  if (fetchStatus === 'failed' && error) {
    return (
      <div className='stay-middle-div bg-[#F8F8F8]'>
        <div className='xl:container w-full mx-auto px-[1rem] md:px-[1.5rem] py-[2%] mx-w-[1536px]'>
          <Stepper />
          <div className='text-center mt-5'>
            <p className='text-[#001A4D]'>{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='stay-middle-div bg-[#F8F8F8]'>
        <div className='w-full xl:container mx-auto px-[1rem] md:px-[1.5rem] py-[2%] mx-w-[1536px]'>
          <Stepper />
          {loading || fetchStatus === 'loading' ? (
            <HotelInfoSkeleton />
          ) : (
            <HotelDetails
              data={hotelInfoData}
              hotelRoomData={groupedRates}
              roomCount={roomCount}
            />
          )}
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Hotel;
